/**
 * first name ,
 * last name,
 * middle name,
 * phone number,
 * gender,
 * age,
 * state,
 * lga,
 * district
 */
const defaultFarmerOnboardingQuestions = [
  {
    answer_options: [],
    correct_answer: [],
    question_title: "First Name",
    question_type: "textField",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 0,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "Last Name",
    question_type: "textField",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 1,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "Middle Name",
    question_type: "textField",
    isCompulsory: false,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 0,
    key: 2,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "Phone Number",
    question_type: "textField",
    isCompulsory: true,
    keyboardType: "number-pad",
    maxLength: 11,
    key: 3,
  },
  {
    answer_options: [
      {
        key: 0,
        text: "Male",
      },
      {
        key: 1,
        text: "Female",
      },
    ],
    correct_answer: [],
    question_title: "Gender",
    question_type: "dropDown",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 4,
  },
  {
    answer_options: [
      {
        key: 0,
        text: "16",
      },
      {
        key: 1,
        text: "17",
      },
      {
        key: 2,
        text: "18",
      },
      {
        key: 3,
        text: "19",
      },
      {
        key: 4,
        text: "20",
      },
      {
        key: 5,
        text: "21",
      },
      {
        key: 6,
        text: "22",
      },
      {
        key: 7,
        text: "23",
      },
      {
        key: 8,
        text: "24",
      },
      {
        key: 9,
        text: "25",
      },
      {
        key: 10,
        text: "26",
      },
      {
        key: 11,
        text: "27",
      },
      {
        key: 12,
        text: "28",
      },
      {
        key: 13,
        text: "29",
      },
      {
        key: 14,
        text: "30",
      },
      {
        key: 15,
        text: "31",
      },
      {
        key: 16,
        text: "32",
      },
      {
        key: 17,
        text: "33",
      },
      {
        key: 18,
        text: "34",
      },
      {
        key: 19,
        text: "35",
      },
      {
        key: 20,
        text: "36",
      },
      {
        key: 21,
        text: "37",
      },
      {
        key: 22,
        text: "38",
      },
      {
        key: 23,
        text: "39",
      },
      {
        key: 24,
        text: "40",
      },
      {
        key: 25,
        text: "41",
      },
      {
        key: 26,
        text: "42",
      },
      {
        key: 27,
        text: "43",
      },
      {
        key: 28,
        text: "44",
      },
      {
        key: 29,
        text: "45",
      },
      {
        key: 30,
        text: "46",
      },
      {
        key: 31,
        text: "47",
      },
      {
        key: 32,
        text: "48",
      },
      {
        key: 33,
        text: "49",
      },
      {
        key: 34,
        text: "50",
      },
      {
        key: 35,
        text: "51",
      },
      {
        key: 36,
        text: "52",
      },
      {
        key: 37,
        text: "53",
      },
      {
        key: 38,
        text: "54",
      },
      {
        key: 39,
        text: "55",
      },
      {
        key: 40,
        text: "56",
      },
      {
        key: 41,
        text: "57",
      },
      {
        key: 42,
        text: "58",
      },
      {
        key: 43,
        text: "59",
      },
      {
        key: 44,
        text: "60",
      },
      {
        key: 45,
        text: "61",
      },
      {
        key: 46,
        text: "62",
      },
      {
        key: 47,
        text: "63",
      },
      {
        key: 48,
        text: "64",
      },
      {
        key: 49,
        text: "65",
      },
      {
        key: 50,
        text: "66",
      },
      {
        key: 51,
        text: "67",
      },
      {
        key: 52,
        text: "68",
      },
      {
        key: 53,
        text: "69",
      },
      {
        key: 54,
        text: "70",
      },
      {
        key: 55,
        text: "71",
      },
      {
        key: 56,
        text: "72",
      },
      {
        key: 57,
        text: "73",
      },
      {
        key: 58,
        text: "74",
      },
      {
        key: 59,
        text: "75",
      },
    ],
    correct_answer: [],
    question_title: "Age",
    question_type: "dropDown",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 5,
  },
  {
    answer_options: [
      {
        key: 0,
        text: "Nigeria",
      },
      {
        key: 1,
        text: "Kenya",
      },
      {
        key: 2,
        text: "Uganda",
      },
      {
        key: 3,
        text: "Sierra Leone",
      },
      {
        key: 3,
        text: "Ghana",
      },
    ],
    correct_answer: [],
    question_title: "Country",
    question_type: "dropDown",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 6,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "State",
    question_type: "dropdownA",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 7,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "LGA",
    question_type: "dropdownB",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 8,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "Community",
    question_type: "dropdownC",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 9,
  },
  {
    answer_options: [],
    question_title: "Village",
    question_type: "textField",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 10,
  },
  {
    answer_options: [],
    correct_answer: [],
    question_title: "District",
    question_type: "textField",
    isCompulsory: true,
    keyboardType: "default",
    maxLength: 1000,
    minLength: 1,
    key: 11,
  },
];

export const ONBOARDING_DEFAULT_QUESTIONS_COUNT = defaultFarmerOnboardingQuestions.length;

export function transformDefaultQuestionValues({ key, value, replace }) {
  const foundIndex = defaultFarmerOnboardingQuestions.findIndex(item => item[`${key}`] === value);

  if (foundIndex > -1) {
    const newQuestions = [...defaultFarmerOnboardingQuestions];
    newQuestions[foundIndex][`${key}`] = replace;
    return newQuestions;
  }

  return defaultFarmerOnboardingQuestions;
}

export default defaultFarmerOnboardingQuestions;
