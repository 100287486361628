import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FarmMarker } from "./farmMarker";
import FarmInfoWindow from "./farmInfoWindow";

export const ClusteredFarmMarkers = ({ farms }) => {
  const [markers, setMarkers] = useState({});
  const [selectedFarmKey, setSelectedFarmKey] = useState(null);

  const selectedFarm = useMemo(
    () => (markers && selectedFarmKey ? farms.find(marker => marker.key === selectedFarmKey) : null),
    [markers, selectedFarmKey]
  );

  const map = useMap();

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedFarmKey(null);
  }, []);

  const handleMarkerClick = useCallback(marker => {
    setSelectedFarmKey(marker.key);
  }, []);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key])) {
        return markers;
      }

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: ignored, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  console.log({ farms, selectedFarm, map, clusterer });

  return (
    <>
      {farms.map(farm => (
        <FarmMarker setMarkerRef={setMarkerRef} key={farm.key} farm={farm} onClick={handleMarkerClick} />
      ))}
      <FarmInfoWindow
        farm={selectedFarm}
        isOpen={!!selectedFarmKey}
        onClose={handleInfoWindowClose}
        anchor={markers[selectedFarmKey]}
      />
    </>
  );
};
