import { getOrg } from "services/v1/authService";

import shortText from "assets/icons/short_text.svg";
import formatAlignLeft from "assets/icons/format_align_left.svg";
import radioButtonChecked from "assets/icons/radio_button_checked.svg";
import arrowDropdownCircle from "assets/icons/arrow_drop_down_circle.svg";
import selectCheckBox from "assets/icons/select_check_box.svg";
import southAmerica from "assets/icons/south_america.svg";
import flagCirlce from "assets/icons/flag_circle.svg";
import numbers from "assets/icons/numbers.svg";
import calendarMonth from "assets/icons/calendar_month.svg";
import { isString, appGet } from "utils";
import { badgeConstants } from "organic";
import { ONBOARDING_DEFAULT_QUESTIONS_COUNT } from "./defaultFarmerOnboardingQuestions";

// Constants
export const PSYCHOMETRIC_TEST_QUESTIONNAIRE = "psychometricTest";
export const FARMER_ONBOARDING_QUESTIONNAIRE = "farmerOnboarding";
export const SURVEY_QUESTIONNAIRE = "survey";
export const FARM_MAPPING_QUESTIONNAIRE = "farmMapping";
export const HARVEST_QUESTIONNAIRE = "harvest";
export const FARM_MONITORING_QUESTIONNAIRE = "farmMonitoring";

// Values
export const questionInputTypes = [
  { value: "textField", label: "Short Text" },
  { value: "textArea", label: "Long Text" },
  { value: "dropDown", label: "Dropdown" },
  { value: "checkbox", label: "Checkbox" },
  { value: "radio", label: "Radio" },
  { value: "dropdownA", label: "State Select" },
  { value: "dropdownB", label: "LGA Select" },
  { value: "dropdownC", label: "Community Select" },
  { value: "accountNumber", label: "Account Number" },
  { value: "date", label: "Date" },
  { value: "bvn", label: "BVN" },
  { value: "imageSelector", label: "Image" },
  { value: "signature", label: "Signature" },
  { value: "offlineMap", label: "Offline Map" },
  { value: "tourbaDropdown", label: "Tourba Dropdown" },
];

export const compulsory = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

/** React Native keyboard types for text field questions */
export const keyBoardTypes = [
  { value: "default", label: "Default" },
  { value: "number-pad", label: "Number Pad" },
  { value: "decimal-pad", label: "Decimal Pad" },
  { value: "phone-pad", label: "Phone Pad" },
  { value: "email-address", label: "Email Address" },
];

export const questionTypeIcons = {
  textField: shortText,
  textArea: formatAlignLeft,
  dropDown: arrowDropdownCircle,
  checkbox: selectCheckBox,
  radio: radioButtonChecked,
  dropdownA: southAmerica,
  dropdownB: flagCirlce,
  accountNumber: numbers,
  date: calendarMonth,
  bvn: numbers,
  imageSelector: selectCheckBox,
  signature: selectCheckBox,
  offlineMap: selectCheckBox,
  tourbaDropdown: arrowDropdownCircle,
};

export const surveyTypes = {
  BVN_SURVEY: "bvn",
  WAREHOUSE_SURVEY: "warehouse",
  MARKETPLACE_SURVEY: "marketplace",
  RISK_SURVEY: "risk",
};
export const farmMonitoringTypes = {
  SEEDING: "Seeding",
  FERTILISATIONS: "Fertilisations",
  PHYTOSANITATION: "Phytosanitation",
  IRRIGATION: "Irrigation",
  HARVEST: "Harvest",
};

export const surveyTypeOptions = [
  { label: "BVN Collection", value: surveyTypes.BVN_SURVEY },
  { label: "Warehouse", value: surveyTypes.WAREHOUSE_SURVEY },
  { label: "Marketplace", value: surveyTypes.MARKETPLACE_SURVEY },
  { label: "Risk", value: surveyTypes.RISK_SURVEY },
];

export const farmMonitoringTypeOptions = [
  { label: "Seeding", value: farmMonitoringTypes.SEEDING },
  { label: "Fertilisations", value: farmMonitoringTypes.FERTILISATIONS },
  { label: "Phytosanitation", value: farmMonitoringTypes.PHYTOSANITATION },
  { label: "Irrigation", value: farmMonitoringTypes.IRRIGATION },
  { label: "Harvest", value: farmMonitoringTypes.HARVEST },
];

export const allQuestionnaireTypes = [
  { value: PSYCHOMETRIC_TEST_QUESTIONNAIRE, label: "Psychometric Test" },
  { value: FARMER_ONBOARDING_QUESTIONNAIRE, label: "Farmer Onboarding" },
  { value: SURVEY_QUESTIONNAIRE, label: "Survey" },
  { value: FARM_MAPPING_QUESTIONNAIRE, label: "Farm Mapping" },
  { value: HARVEST_QUESTIONNAIRE, label: "Harvest" },
  { value: FARM_MONITORING_QUESTIONNAIRE, label: "Farm Monitoring" },
];

export const initialCreateQuestionnaireValues = {
  name: "",
  organization: getOrg() ?? "",
  season: "",
  farm_operation_category: [],
  farm_sub_category: "",
  category_type: [],
  psychometrics_status: false,
  model: "",
  questionnaire_type: "",
  score_per_question: 0,
  cut_off_mark: 0,
  category_crop_type: "",
  status: "active",
  questions: [],
};

// Utils
export const createNewQuestionObjWithIndexKey = index => ({
  [`${index}`]: {
    question_title: "",
    question_type: "",
    answer_options: [],
    correct_answer: [],
    isCompulsory: false,
    keyboardType: keyBoardTypes[0].value,
    maxLength: "",
  },
});

export const isBvnSurvey = questionnaire =>
  questionnaire.model === SURVEY_QUESTIONNAIRE && questionnaire.question_type === surveyTypes.BVN_SURVEY;

export const isSurvey = questionnaire => questionnaire.model === SURVEY_QUESTIONNAIRE;

export const handleAnswerOptionsChange = (
  newValue,
  { actionMeta, setFieldValue, currentQuestionIndex, currentQuestionKey, values }
) => {
  switch (actionMeta.action) {
    case "clear": {
      // answer options is cleared
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.correct_answer`, []);
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.answer_options`, []);
      return;
    }
    case "create-option": {
      // new answer options is manually entered
      console.log({ newValue });
      const transformedValues = newValue.map((value, valueIndex) => {
        return { key: valueIndex, text: value?.value || value?.text };
      });
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.answer_options`, transformedValues);
      return;
    }
    case "select-option": {
      // an answer option that was previously removed was reselected
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.answer_options`, actionMeta.option);
      return;
    }
    case "remove-value": {
      // an answer option that was created is removed from list (still available to reselect)
      let _correct_answer = appGet(values, [currentQuestionKey, currentQuestionIndex, "correct_answer"], []);
      const newCorrectAnswers = _correct_answer.filter(
        _answer => `${_answer.value}` !== `${actionMeta.removedValue.value}`
      );
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.correct_answer`, newCorrectAnswers);
      setFieldValue(`${currentQuestionKey}.${currentQuestionIndex}.answer_options`, newValue);
      return;
    }
    default: {
      return;
    }
  }
};

export const handleEditFarmOperationCategory = (value, { setValues, values }) => {
  let _farmOperationValues = [];
  if (!value) {
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      },
    });
    return;
  }
  if (Array.isArray(value)) {
    _farmOperationValues = value;
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      },
    });
    return;
  }
  if (isString(value)) {
    _farmOperationValues = [value];
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        farm_operation_category: _farmOperationValues,
        category_type: [],
        category_crop_type: "",
      },
    });
  }
};

export const handleEditCategoryType = (value, { setValues, values }) => {
  let _categoryTypeValues = [];
  if (!value) {
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        category_type: _categoryTypeValues,
        category_crop_type: "",
      },
    });
    return;
  }
  if (Array.isArray(value)) {
    _categoryTypeValues = values;
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        category_type: _categoryTypeValues,
        category_crop_type: _categoryTypeValues[0],
      },
    });
    return;
  }

  if (isString(value)) {
    _categoryTypeValues = [value];
    setValues({
      ...values,
      questionnaireDetails: {
        ...values.questionnaireDetails,
        category_type: _categoryTypeValues,
        category_crop_type: value,
      },
    });
  }
};

export const initialiseNonQuestionFields = questionnaire => ({
  name: questionnaire?.name || "",
  organization: getOrg(),
  season: questionnaire?.season || "",
  farm_operation_category: questionnaire?.farm_operation_category || [],
  category_type: questionnaire?.category_type || [],
  psychometrics_status: questionnaire?.psychometrics_status || false,
  model: questionnaire.model,
  questionnaire_type: questionnaire?.questionnaire_type || "",
  score_per_question: questionnaire?.score_per_question || 0,
  cut_off_mark: questionnaire?.cut_off_mark || 0,
  category_crop_type: questionnaire.category_crop_type || "",
  status: questionnaire?.status || "active",
});
export const initialiseQuestionnaireForMarketplace = questionnaire => ({
  name: questionnaire?.name || "",
  organization: getOrg(),
  season: questionnaire?.season || "",
  farm_operation_category: questionnaire?.farm_operation_category || [],
  category_type: questionnaire?.category_type || [],
  psychometrics_status: questionnaire?.psychometrics_status || false,
  questionnaire_type: questionnaire?.questionnaire_type || "",
  model: questionnaire.model,
  score_per_question: questionnaire?.score_per_question || 0,
  cut_off_mark: questionnaire?.cut_off_mark || 0,
  category_crop_type: questionnaire.category_crop_type || "",
  status: questionnaire?.status || "active",
  prices: questionnaire?.prices || {},
});

export const initialiseCreateSingleQuestionValue = questionTypeIndex => ({
  question_title: "",
  question_type: questionInputTypes[questionTypeIndex].value,
  answer_options: [],
  correct_answer: [],
  isCompulsory: false,
  keyboardType: keyBoardTypes[0].value,
  maxLength: 1000,
});

export const v2HandleInitializeQuestionnaireEdit = _questionnaire => {
  let _transformedQuestionnaire = {};
  let _questionnaireDetails = initialiseNonQuestionFields(_questionnaire);
  let _defaultQuestions;
  let _existingQuestions = [..._questionnaire.questions];
  if (_questionnaire.model === FARMER_ONBOARDING_QUESTIONNAIRE) {
    _defaultQuestions = _existingQuestions.splice(0, ONBOARDING_DEFAULT_QUESTIONS_COUNT);
  } else {
    _defaultQuestions = [];
  }
  _transformedQuestionnaire.questionnaireDetails = _questionnaireDetails;
  _transformedQuestionnaire.defaultQuestions = _defaultQuestions;
  _transformedQuestionnaire.existingQuestions = _existingQuestions;
  _transformedQuestionnaire.newQuestions = [];
  console.log("form init", { _transformedQuestionnaire });

  return _transformedQuestionnaire;
};

export const checkForQuestionnaireType = ({ questionnaire, type }) => {
  return questionnaire?.questionnaire_type === type;
};
export const checkForQuestionnaireModel = ({ questionnaire, model }) => {
  return questionnaire?.model === model;
};

export const getQuestionnaireModelBadge = model => {
  let badgeColors = Object.values(badgeConstants.colorSchemes);
  const models = allQuestionnaireTypes.map(questionnaire => questionnaire.value);

  if (badgeColors?.length < models.length) {
    let offset = models?.length - badgeColors?.length;
    const reusedBadges = badgeColors.slice(0, offset);
    badgeColors = [...badgeColors, ...reusedBadges];
  }

  return badgeColors[models.indexOf(model)];
};
