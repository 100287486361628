import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllFarmMappingsWithParams,
  searchAllFarmMappingsWithParams,
  getFarmMappingData,
  // getLoadingFarmMapping,
  getFarmMappingMeta,
} from "features/v1/farmMapping";
import { Table } from "components/table";
import { useDisclosure, useTable } from "hooks";
import { legacyFormatDate } from "utils/v1/tableUtils";
import { Flex } from "organic";
import { Modal, ModalContent } from "components/modal";
import ViewMappingDetails from "./viewMappingDetails";
import EditMappingDetails from "./editMappingDetails";
import { convertObjectToLatLngObject, appGet, formatDate } from "utils";
// import { useGoogleMaps } from "hooks/app";
import { FarmMappingSelect } from "components/select";
import { jsonToCSV } from "react-papaparse";
import { getAOSFlavour } from "services/customerService";
import { toast } from "react-toastify";
import _ from "lodash";
import GoogleMap from "components/map/googleMap";
import { VisibilityWrapper } from "components/layout";
import { useAOSFlavour } from "hooks/app/multitenant/useAosFlavour";
import { GoogleMapPolygon } from "components/map/googleMapPolygon";
import { ClusteredFarmMarkers } from "components/map/clusteredFarmMarkers";

const generateMapperCsvFilename = () => {
  const flavour = getAOSFlavour();
  return `${_.upperCase(flavour)}_Mapper_PolygonExport${formatDate(new Date())}.csv`;
};
const MAP_ID = process.env.REACT_APP_MAPPER_MAP_ID;
const MappingOverviewPage = () => {
  const dispatch = useDispatch();

  const [__hoverId, __setHoverId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [__selectedMarker, setSelectedMarker] = useState(null);
  const [__infoWindowShown, setInfoWindowShown] = useState(false);
  const [mapType, setMapType] = useState("satellite");

  let farmMappingData = useSelector(getFarmMappingData);
  // let loading = useSelector(getLoadingFarmMapping);
  let farmMappingMeta = useSelector(getFarmMappingMeta);

  const farmMappingModal = useDisclosure();
  const farmMappingEditModal = useDisclosure();

  const { aosFlavour } = useAOSFlavour();

  // const { isMapLoaded, drawPolygon, placeManyMarkers, drawManyPolygons } = useGoogleMaps({
  //   libraries: ["core", "maps", "marker", "geometry", "geocode"],
  // });

  // const Z_INDEX_SELECTED = farmMappingData.length;
  // const Z_INDEX_HOVER = farmMappingData.length + 1;

  const farms = useMemo(() => {
    if (!farmMappingData || !farmMappingData?.length) {
      return [];
    }
    return farmMappingData
      .filter(mapping => mapping?.coordinate)
      .sort((a, b) => {
        return (
          convertObjectToLatLngObject(a.coordinate, {
            latKey: "latitude",
            lngKey: "longtitude",
          }).lat -
          convertObjectToLatLngObject(b.coordinate, {
            latKey: "latitude",
            lngKey: "longtitude",
          }).lat
        );
      })
      .map((mapping, __index) => {
        // let zIndex = index;

        // if (hoverId === mapping.key) {
        //   zIndex = Z_INDEX_HOVER;
        // }

        // if (selectedId === mapping.key) {
        //   zIndex = Z_INDEX_SELECTED;
        // }
        return {
          key: mapping._id,
          farmer: mapping.farmer,
          crop_type: mapping.crop_type,
          position: convertObjectToLatLngObject(mapping.coordinate, {
            latKey: "latitude",
            lngKey: "longtitude",
          }),
          // zIndex: zIndex,
        };
        // (
        //   <GoogleMapsMarker
        //     key={hyphenate([mapping.key, index])}
        //     position={convertObjectToLatLngObject(mapping.coordinate, {
        //       latKey: "latitude",
        //       lngKey: "longtitude",
        //     })}
        //     zIndex={zIndex}
        //     onMouseEnter={() => setHoverId(mapping.key)}
        //     onMouseLeave={() => setHoverId(null)}
        //     onClick={marker => onMarkerClick(index, marker)}
        //   />
        // );
      });
  }, [farmMappingData]);

  const farmMaps = useMemo(() => {
    if (!farmMappingData?.length) {
      return [];
    }

    const transformedMappingData = farmMappingData
      ?.filter(mapping => mapping?.mappedFarm?.routeCoordinates)
      .map(mapping => ({
        key: mapping._id,
        positions: mapping?.mappedFarm?.routeCoordinates.map(coord =>
          convertObjectToLatLngObject(coord, {
            latKey: "latitude",
            lngKey: "longitude",
          })
        ),
      }));

    return transformedMappingData;
  }, [farmMappingData]);

  // const { isMapLoaded, flyTo } = useLeafletMaps(farmMappingData);

  const { tableProps, selectedRow: selectedFarmer } = useTable({
    data: farmMappingData,
    columns: [
      {
        Header: "ID",
        accessor: "ta_number",
      },
      {
        id: "mappingCrop",
        Header: "Crop Type",
        accessor: mapping => appGet(mapping, ["crop_type", "name"], "N/A"),
      },
      {
        id: "mappingDate",
        Header: "Date",
        accessor: mapperInfo => legacyFormatDate(mapperInfo?.createed_at),
      },
      // {
      //   id: "mappingAgent",
      //   Header: "Agent",
      //   accessor: mapperInfo => `${mapperInfo?.agent?.first_name || "N/A"} ${mapperInfo?.agent?.last_name || "N/A"}`,
      // },
      {
        id: "mappingFarmer",
        Header: "Farmer",
        accessor: mapperInfo => `${mapperInfo?.farmer?.first_name || "N/A"} ${mapperInfo?.farmer?.last_name || "N/A"}`,
      },
      {
        Header: "Farm Size (ha)",
        accessor: "farm_size",
      },
      {
        id: "mappingAccuracy",
        Header: "Accuracy",
        accessor: mapperInfo => mapperInfo?.coordinate?.accuracy || "N/A",
      },
      {
        Header: "Adjusted Farm Size (ha)",
        accessor: "adjusted_farm_size",
      },
    ],
    title: "All Mappings",
    useServerPagination: true,
    serverPaginationMeta: farmMappingMeta,
    noViewAction: true,
  });

  const ignoredhandleExportPolygons = ({ format }) => {
    if (format === "csv") {
      const FarmMapsJson = JSON.stringify(
        farmMappingData
          .filter(unfiltered => unfiltered?.mappedFarm)
          .map(mapping => {
            return {
              ...JSON.parse(JSON.stringify(mapping?.mappedFarm)),
              id: mapping.ta_number,
              size: mapping.farm_size,
            };
          })
      );
      const farmMapsCSV = jsonToCSV(FarmMapsJson);
      if (farmMapsCSV && farmMapsCSV.length > 0) {
        // export successfull
        let hiddenElement = document.createElement("a");
        let blob = new Blob(["\ufeff", farmMapsCSV]);
        let url = URL.createObjectURL(blob);
        hiddenElement.href = url;
        // hiddenElement.target = "_blank";)
        hiddenElement.download = generateMapperCsvFilename();
        hiddenElement.click();
        hiddenElement.remove(); // experimental, hopefully prevent creating too many nodes
        toast.success("Maps Polygon Export Successful");
      } else {
        toast.warn("No data for the current filter, try a different filter");
      }
    }
  };

  const ignoredtoggleMapType = () => {
    setMapType(prevType => (prevType === "roadmap" ? "satellite" : "roadmap"));
  };

  const onMapClick = useCallback(() => {
    setSelectedId(null);
    setSelectedMarker(null);
    setInfoWindowShown(false);
  }, []);

  const ignoredonMarkerClick = useCallback(
    (id, marker) => {
      setSelectedId(id);

      if (marker) {
        setSelectedMarker(marker);
      }

      if (id !== selectedId) {
        setInfoWindowShown(true);
      } else {
        setInfoWindowShown(isShown => !isShown);
      }
    },
    [selectedId]
  );
  const ignoredhandleInfowindowCloseClick = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <Flex flexDirection="column" position="relative" my="2rem">
        {/* Leaflet Map */}
        {/* <VisibilityWrapper isVisible={aosFlavour !== "local"}>
          <LeafletMap ref={mapRef}>
            {farmMappingData?.length
              ? farmMappingData
                  .filter(mapping => mapping?.coordinate)
                  .map(mapping => {
                    return (
                      <LeafletMarker
                        key={nanoid()}
                        position={convertObjectToLatLngObject(mapping.coordinate, {
                          latKey: "latitude",
                          lngKey: "longtitude",
                        })}
                        info={<MappingInfoWindow mapping={mapping} />}
                      />
                    );
                  })
              : null}
            {farmMaps?.length
              ? farmMaps.map(transformedMapping => {
                  return <LeafletPolygon key={transformedMapping.key} positions={transformedMapping.positions} />;
                })
              : null}
          </LeafletMap>
        </VisibilityWrapper> */}
        {/* Google Map */}
        <VisibilityWrapper isVisible={aosFlavour === "local"}>
          <GoogleMap height={400} onClick={onMapClick} mapId={MAP_ID} mapTypeId={mapType} width="100%">
            {farms?.length ? <ClusteredFarmMarkers farms={farms} /> : null}
            {farmMaps?.length
              ? farmMaps.map(transformedMapping => {
                  return <GoogleMapPolygon key={transformedMapping.key} paths={transformedMapping.positions} />;
                })
              : null}
            {/* {selectedMarker && (
              <FarmInfoWindow isOpen={infoWindowShown} anchor={selectedMarker} onClose={handleInfowindowCloseClick}>
                <MappingInfoWindow />
              </FarmInfoWindow>
            )} */}
          </GoogleMap>
        </VisibilityWrapper>

        <Flex position="absolute" zIndex={999999} right="1rem" gap="1rem" top="1rem" minWidth="360px">
          <FarmMappingSelect
            isDisabled
            onChange={() => {
              // console.log({ farm, isMapLoaded });
              // if (isMapLoaded) {
              // flyTo(
              //   convertObjectToLatLngObject(farm.coordinate, {
              //     latKey: "latitude",
              //     lngKey: "longtitude",
              //   })
              // );
              // }
            }}
          />
          {/* <Select
            options={[{ label: "Export as csv", value: "csv" }]}
            onChange={val => {
              handleExportPolygons({ format: val });
            }}
            width="180px"
            isDisabled
            placeholder="Export Polygons"
          /> */}
        </Flex>
        {/* <Flex mt="1rem">
          <Button
            size={buttonConstants.sizes.LG}
            onClick={ignoredtoggleMapType}
            colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
          >
            Show {mapType === "satellite" ? "Roadmap" : "Satellite"}
          </Button>
        </Flex> */}
      </Flex>
      {/* </VisibilityWrapper> */}
      {/* <VisibilityWrapper isVisible={!farmMappingData?.length}>
        <EmptyState title="Map View" subtitle="No data to show  on map" />
      </VisibilityWrapper> */}

      {/* Table */}
      <Table
        {...tableProps}
        onViewAction={() => {
          farmMappingModal.onOpen();
        }}
        onDataFilter={filterParams => {
          dispatch(getAllFarmMappingsWithParams({ ...filterParams }));
        }}
        onSearch={(params, { signal }) => {
          console.log("search callback", { text: params.searchText });
          dispatch(searchAllFarmMappingsWithParams(params, { signal }));
        }}
        onEditAction={() => {
          farmMappingEditModal.onOpen();
        }}
      />
      {/* <ViewFarmMappingDetails modal={farmMappingModal} selected={selectedFarmMapping} />
      <EditFarmMappingDetails
        updateFarmMappingInfo={handleUpdateFarmMappingInfo}
        modal={farmMappingModal}
        selected={selectedFarmMapping}
      /> */}
      {/* view farmer */}
      <Modal
        size
        open={farmMappingModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingModal.onClose();
          }
        }}
      >
        <ModalContent title="View Farmer  Details">
          <ViewMappingDetails />
        </ModalContent>
      </Modal>
      {/* edit farmer */}
      <Modal
        open={farmMappingEditModal.isOpen}
        onOpenChange={open => {
          if (!open) {
            farmMappingEditModal.onClose();
          }
        }}
      >
        <ModalContent title="Edit Farm Mapping Details">
          <EditMappingDetails selected={selectedFarmer} modal={farmMappingEditModal.modal} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default MappingOverviewPage;
