/* eslint-disable no-case-declarations */
import farmerPayment from "api/farmerPayment";
import { toast } from "react-toastify";
import { handleActionError } from "services/shared/AOSErrorService";
import { authChecker } from "services/v1/authService";

export const farmerPaymentRequestStatus = {
  APPROVED: "Approve",
  REJECTED: "Reject",
  PENDING: "Pending",
};

export const farmerApprovedPaymentRequestStatus = {
  PROCESSED: "Processed",
  DECLINED: "Declined",
  PENDING: "Pending",
};

const LOAD_FARMER_PAYMENTS_DATA = "LOAD_FARMERS_DATA";
const GET_ALL_FARMER_PAYMENTS = "GET_ALL_FARMER_PAYMENTS";
const GET_ALL_FARMER_PAYMENTS_NOT_PAGINATED = "GET_ALL_FARMER_PAYMENTS_NOT_PAGINATED";
const GET_ALL_APPROVED_FARMER_PAYMENTS_REQUESTS = "GET_ALL_APPROVED_FARMER_PAYMENTS_REQUESTS";
const UPDATE_FARMER_PAYMENT = "UPDATE_FARMER_PAYMENT";
const GET_FILTERED_PAYMENTS = "GET_FILTERED_PAYMENTS";
const GET_DATE_RANGE = "GET_DATE_RANGE";
const SET_NONPAGINATEDDATA_PARAMS = "SET_NONPAGINATEDDATA_PARAMS";

export const getAllFarmerPaymentsWithParams = params => async dispatch => {
  try {
    authChecker();

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });
    const farmerPaymentsData = await farmerPayment.get("", { params });

    dispatch({
      type: GET_ALL_FARMER_PAYMENTS,
      payload: farmerPaymentsData.data.farmerPayment.data,
      farmerPaymentsPageCount: farmerPaymentsData?.data?.farmerPayment.meta?.pageCount || 0,
      totalFarmerPaymentsCount: farmerPaymentsData?.data?.farmerPayment.meta?.totalDocCount || 0,
      farmerPaymentsMeta: farmerPaymentsData?.data?.farmerPayment.meta || {},
      status: params.status,
      payment_status: params.payment_status,
    });

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  } catch (getAllFarmerPaymentsWithParamsError) {
    handleActionError(getAllFarmerPaymentsWithParamsError);
    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  }
};

export const getAllFarmerPaymentsNotPaginated = params => async dispatch => {
  try {
    authChecker();

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });
    const farmerPaymentsData = await farmerPayment.get("", { params });

    dispatch({
      type: GET_ALL_FARMER_PAYMENTS_NOT_PAGINATED,
      payload: farmerPaymentsData.data.farmerPayment.data,
      farmerPaymentsPageCount: farmerPaymentsData?.data?.farmerPayment.meta?.pageCount || 0,
      totalFarmerPaymentsCount: farmerPaymentsData?.data?.farmerPayment.meta?.totalDocCount || 0,
      farmerPaymentsMeta: farmerPaymentsData?.data?.farmerPayment.meta || {},
    });

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  } catch (getAllFarmerPaymentsWithParamsError) {
    handleActionError(getAllFarmerPaymentsWithParamsError);
    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  }
};

export const searchAllFarmerPaymentsWithParams =
  (params, { abortController }) =>
  async dispatch => {
    try {
      authChecker();

      dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });
      const farmerSearchData = await farmerPayment.get("search", { params, abortController });

      dispatch({
        type: GET_FILTERED_PAYMENTS,
        payload: farmerSearchData.data.data,
        farmerPaymentsPageCount: farmerSearchData?.data?.meta?.pageCount || 0,
        totalFarmerPaymentsCount: farmerSearchData?.data?.meta?.totalDocCount || 0,
        farmerPaymentsMeta: farmerSearchData?.data?.meta || {},
      });

      dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
    } catch (searchAllFarmerPaymentsWithParams) {
      handleActionError(searchAllFarmerPaymentsWithParams);
      dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
    }
  };

export const getAllApprovedPaymentRequestsWithParams = params => async dispatch => {
  try {
    authChecker();

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });
    const farmerPaymentsData = await farmerPayment.get("", {
      params,
    });

    dispatch({
      type: GET_ALL_APPROVED_FARMER_PAYMENTS_REQUESTS,
      payload: farmerPaymentsData.data.farmerPayment.data,
      farmerPaymentsPageCount: farmerPaymentsData?.data?.farmerPayment.meta?.pageCount || 0,
      totalFarmerPaymentsCount: farmerPaymentsData?.data?.farmerPayment.meta?.totalDocCount || 0,
      farmerPaymentsMeta: farmerPaymentsData?.data?.farmerPayment.meta || {},
      payment_status: params.payment_status,
    });

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  } catch (getAllFarmerPaymentsWithParamsError) {
    handleActionError(getAllFarmerPaymentsWithParamsError);
    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
  }
};

export const getFarmerPaymentDateRange = dateRange => ({
  type: GET_DATE_RANGE,
  payload: dateRange,
});

export const setNonPaginatedDataParams = params => ({
  type: SET_NONPAGINATEDDATA_PARAMS,
  payload: params,
});

export const updateFarmerPayment =
  (values, config = {}) =>
  async (dispatch, getState) => {
    // console.log("Config:", config.modal);
    // try {
    authChecker();

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });

    const params = {
      farmerPaymentId: config.farmerPaymentId,
      tradeId: config.tradeId,
      unit: config.unit,
    };

    // const updatedFarmerPaymentData =
    farmerPayment
      .put("updateFarmerPayment", values, { params })
      .then(response => {
        if (response.status === 200) {
          toast.success(
            `Farmer payment ${
              config.type === "approve"
                ? "approved"
                : config.type === "reject"
                ? "rejected"
                : config.type === "payout"
                ? "processed"
                : "declined"
            }`
          );
          config?.modal?.hideModal();
          if (config.resetForm) {
            config?.resetForm();
          }
          if (config.setActiveTab) {
            config?.setActiveTab();
          }
          dispatch({
            type: UPDATE_FARMER_PAYMENT,
            payload: response.data.data,
          });
          dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
          // Set params after update
          dispatch(setNonPaginatedDataParams(getState().nonPaginatedDataParams));
        } else if (response.status === 401) {
          toast.error("You are not authorized to do that!");
        }
        dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
      })
      .catch(updateFarmerPaymentError => {
        handleActionError(updateFarmerPaymentError);
        dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
      });
  };
export const updateBulkFarmerPayment =
  (values, config = {}) =>
  async (dispatch, getState) => {
    // console.log("Config:", config.modal);
    // try {
    authChecker();

    dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: true });

    // const params = {
    //   farmerPaymentId: config.farmerPaymentId,
    //   tradeId: config.tradeId,
    //   unit: config.unit,
    // };

    // const updatedFarmerPaymentData =
    farmerPayment
      .put("updateManyFarmerPayment", values)
      .then(response => {
        if (response.status === 200) {
          toast.success(
            `${response?.data?.data?.nModified || ""} Farmer payments ${
              config.type === "approve"
                ? "approved"
                : config.type === "reject"
                ? "rejected"
                : config.type === "payout"
                ? "processed"
                : "declined"
            }`
          );
          config?.modal?.hideModal();
          if (config.resetForm) {
            config?.resetForm();
          }
          if (config.setActiveTab) {
            config?.setActiveTab();
          }
          dispatch({
            type: UPDATE_FARMER_PAYMENT,
            payload: response.data.data,
          });
          dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
          // Set params after update
          dispatch(setNonPaginatedDataParams(getState().nonPaginatedDataParams));
        } else if (response.status === 401) {
          toast.error("You are not authorized to do that!");
        }
        dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
      })
      .catch(updateFarmerPaymentError => {
        handleActionError(updateFarmerPaymentError);
        dispatch({ type: LOAD_FARMER_PAYMENTS_DATA, payload: false });
      });
  };

//reducer
function middlewareFilterForFarmerPayments(state, action) {
  try {
    let farmerPaymentsRecord = action.payload;

    return {
      ...state,
      farmerPaymentsData: farmerPaymentsRecord,
      totalFarmerPaymentsCount: action.totalFarmerPaymentsCount,
      farmerPaymentsMeta: action.farmerPaymentsMeta,
      loading: false,
    };
  } catch (error) {
    console.log({ error });
  }
}

const initialState = {
  loading: false,
  farmerPaymentsData: [],
  farmerPaymentsDataNonPaginated: [],
  dateRange: null,
  nonPaginatedDataParams: {},
  farmerPaymentsMeta: 0,
  totalFarmerPaymentsCount: 0,
  changed: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FARMER_PAYMENTS_DATA:
      return { ...state, loading: action.payload };
    case GET_ALL_FARMER_PAYMENTS:
      return middlewareFilterForFarmerPayments(state, action);
    case GET_ALL_APPROVED_FARMER_PAYMENTS_REQUESTS:
      return middlewareFilterForFarmerPayments(state, action);
    case GET_FILTERED_PAYMENTS:
      return middlewareFilterForFarmerPayments(state, action);
    case GET_DATE_RANGE:
      return { ...state, dateRange: action.payload };
    case SET_NONPAGINATEDDATA_PARAMS:
      return { ...state, nonPaginatedDataParams: action.payload };
    case GET_ALL_FARMER_PAYMENTS_NOT_PAGINATED:
      const farmerPaymentsRecord = action.payload;
      return {
        ...state,
        farmerPaymentsDataNonPaginated: farmerPaymentsRecord,
      };
    case UPDATE_FARMER_PAYMENT:
      const updatedFarmerPaymentsData = state.farmerPaymentsData.map(d => {
        if (d._id === action.payload._id) {
          d = action.payload;
        }
        return d;
      });
      return { ...state, farmerPaymentsData: updatedFarmerPaymentsData };
    default:
      return state;
  }
};

// selector functions
export const getFarmerPaymentsData = state => state.legacyFarmerPayment.farmerPaymentsData;
export const getFarmerPaymentsDataNonPaginated = state => state.legacyFarmerPayment.farmerPaymentsDataNonPaginated;
export const getFarmerPaymentsMeta = state => state.legacyFarmerPayment.farmerPaymentsMeta;
export const getLoadingFarmerPayment = state => state.legacyFarmerPayment.loading;
export const farmerPaymentDateRange = state => state.legacyFarmerPayment.dateRange;
export const getNonPaginatedDataParams = state => state.legacyFarmerPayment.nonPaginatedDataParams;
