import React from "react";
import { leafletMapConstants } from "../../constants/mapConstants";
import { ErrorState } from "components/appState";
import { Polygon } from "react-leaflet";

const LeafletPolygon = React.forwardRef(({ children, positions, ...restProps }, forwardedRef) => {
  return (
    <ErrorState useTooltip>
      <Polygon
        ref={forwardedRef}
        positions={positions}
        pathOptions={leafletMapConstants.defaults.POLYGON_STYLE}
        {...restProps}
      >
        {children}
      </Polygon>
    </ErrorState>
  );
});

LeafletPolygon.displayName = "LeafletPolygon";

export { LeafletPolygon };
