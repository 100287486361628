import { DropdownContent, DropdownItem, DropdownMenu, DropdownTrigger } from "components/dropdown";
import { Badge, Icon, Image, Text, badgeConstants, organicTheme } from "organic";
import React from "react";
import { appGet, hyphenate, isEmptyObject } from "utils";
import {
  FARMER_ONBOARDING_QUESTIONNAIRE,
  FARM_MONITORING_QUESTIONNAIRE,
  initialiseCreateSingleQuestionValue,
  questionInputTypes,
  questionTypeIcons,
} from "utils/questionnaire-utils/questionnaire-utils";
import { useDisclosure, useOrgData } from "hooks";
import { useFormikContext } from "formik";

const noop = () => null;

const QuestionListItem = ({
  icon,
  title,
  readOnly,
  subtitle,
  currentQuestionIndex,
  currentQuestionKey,
  questionKey,
  index,
  onSelect,
  onDelete,
  noActions,
  badge,
}) => {
  // const __previousQuestionIndex = React.useRef(index)

  const { errors } = useFormikContext();

  const hasErrors = React.useMemo(() => {
    const _errors = !isEmptyObject(appGet(errors, [questionKey, index], {})) ? true : false;
    return _errors;
  }, [errors]);

  return (
    <div
      className="block-item"
      data-read-only={readOnly ? "enabled" : "disabled"}
      data-status={currentQuestionIndex === index && currentQuestionKey === questionKey ? "active" : "inactive"}
      data-error-state={hasErrors ? "enabled" : "disabled"}
    >
      <div onClick={onSelect} className="block-item__icon">
        <Image src={icon} />
      </div>
      <div onClick={onSelect} className="block-item__content">
        <div className="block-item__title">{title || "[Untitled]"}</div>
        <div className="block-item__subtitle">
          {`${subtitle || ""} ${readOnly === true ? " - (Read Only)" : ""}`}
          {badge ? (
            <Badge ml="4px" size="sm" colorScheme={badgeConstants.colorSchemes.ROSE}>
              {badge}
            </Badge>
          ) : null}
        </div>
      </div>
      {!noActions && !readOnly ? (
        <div className="block-item__actions">
          <DropdownMenu>
            <DropdownTrigger>
              <Icon icon="more-vertical" color={organicTheme.colors.gray700} size={20} />
            </DropdownTrigger>
            <DropdownContent unstyled>
              <DropdownItem onSelect={readOnly === true ? noop : onDelete}>
                <Icon icon={"trash"} size={16} />
                <Text ml="0.75rem" as="span" font="smMedium" color="gray700">
                  Delete
                </Text>
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </div>
      ) : null}
    </div>
  );
};

export const AddBlockButton = ({ onSelect, onAddBlockChange, questionnaire }) => {
  const addBlockDropdown = useDisclosure();
  const { orgSubdomain } = useOrgData();

  React.useEffect(() => {
    onAddBlockChange(addBlockDropdown.isOpen);
  }, [addBlockDropdown.isOpen, onAddBlockChange]);
  return (
    <div className="add-block" data-status={addBlockDropdown.isOpen ? "open" : "closed"}>
      <button className="add-block-trigger" type="button" onClick={() => addBlockDropdown.onOpen()} role="button">
        <div className="add-block-trigger__icon">
          <Icon icon="plus-circle" color="#429A24" />
        </div>
        <div className="add-block-trigger__content">
          <div className="add-block-trigger__title">Add block</div>
        </div>
      </button>
      {addBlockDropdown.isOpen ? (
        <div className="block-options">
          <button type="button" className="block-options__close" onClick={() => addBlockDropdown.onClose()}>
            <>
              <div className="close-icon">
                <Icon icon="x-circle" color="#429A24" />
              </div>
              <Text className="close-label">Choose a Block Type</Text>
            </>
          </button>
          {questionInputTypes
            .filter(inputType => {
              //only show tourba dropdown for tourba org
              if (orgSubdomain === "tourba" && inputType.value === "tourbaDropdown") {
                return true;
              } else if (orgSubdomain !== "tourba" && inputType.value === "tourbaDropdown") return false;

              if (
                questionnaire.questionnaireDetails.model !== FARMER_ONBOARDING_QUESTIONNAIRE &&
                questionnaire.questionnaireDetails.model !== FARM_MONITORING_QUESTIONNAIRE
              ) {
                return true;
              }
              return (
                inputType.value !== ""
                // inputType.value !== "signature" &&
                // inputType.value === "offlineMap"
              );
            })
            .map((questionType, questionTypeIndex) => (
              <button
                type="button"
                className="block-options__item"
                onClick={() => {
                  const _newQuestion = initialiseCreateSingleQuestionValue(questionTypeIndex);
                  onSelect(_newQuestion);
                  addBlockDropdown.onClose();
                }}
                key={hyphenate(["question-type", questionTypeIndex, questionType.value])}
              >
                <>
                  <div className="block-option-icon">
                    <Image src={appGet(questionTypeIcons, questionType.value, "square")} color="#429a24" />
                  </div>
                  <div className="block-option-title">{questionType.label}</div>
                </>
              </button>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default QuestionListItem;
