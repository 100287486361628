/* eslint-disable no-unused-vars */
import React from "react";
import { Badge, Button, Flex, badgeConstants, buttonConstants } from "organic";
import { useTable } from "hooks";
import { farmerApprovedPaymentRequestStatus, farmerPaymentRequestStatus } from "features/v1/farmerPayment";
import { financePage } from "routes/dashboardRoutes";
import { truncateText } from "utils";

const usePaymentsTable = (farmerPaymentsData, farmerPaymentsMeta, farmerPaymentLoading, page, activeTab) => {
  const isPaymentsPage = page === financePage.PAYMENT;
  const columns = [
    {
      Header: "Trade ID",
      accessor: "trade_id",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: cell => {
        const row = cell.row.original;
        return <span>{`${row?.farmer?.first_name} ${row?.farmer?.last_name}`}</span>;
      },
    },
    {
      Header: "Farmer ID",
      accessor: "farmer.ta_number",
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: cell => {
        const row = cell.row.original;
        return <span>{row?.farmer?.phone_number || "--"}</span>;
      },
    },
    {
      Header: "Bank Name",
      accessor: "bankName",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Account No",
      accessor: "accountNumber",
    },
    {
      Header: "CropType",
      accessor: "cropType",
      Cell: cell => {
        const row = cell.row.original;
        return <span>{row?.crop_type?.name || "--"}</span>;
      },
    },
    {
      Header: "Unit(KG)",
      accessor: "unit",
      Cell: ({ value }) => <span>{value}</span>,
    },
    {
      Header: "Price/kg",
      accessor: "price",
      Cell: ({ value }) => <span>{value?.toLocaleString()}</span>,
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ value }) => <span>{value?.toLocaleString()}</span>,
    },
    {
      Header: "Comment",
      accessor: "comment",
      Cell: ({ value }) => <span>{value ? truncateText(value, 20) : "--"}</span>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: cell => {
        const row = cell.row.original;
        return (
          <>
            {isPaymentsPage && (
              <Flex gap="20px" flexDirection="row">
                {row.status === farmerPaymentRequestStatus.REJECTED && (
                  <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Rejected</Badge>
                )}
                {row.status === farmerPaymentRequestStatus.APPROVED && (
                  <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Approved</Badge>
                )}
                {row.status === farmerPaymentRequestStatus.PENDING && (
                  <Badge colorScheme={badgeConstants.colorSchemes.BLUE_LIGHT}>Pending</Badge>
                )}
              </Flex>
            )}
            {!isPaymentsPage && (
              <Flex gap="20px" flexDirection="row">
                {row.payment_status === farmerApprovedPaymentRequestStatus.DECLINED && (
                  <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Declined</Badge>
                )}
                {row.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED && (
                  <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Processed</Badge>
                )}
                {row.payment_status === farmerApprovedPaymentRequestStatus.PENDING && (
                  <Badge colorScheme={badgeConstants.colorSchemes.BLUE_LIGHT}>Pending</Badge>
                )}
              </Flex>
            )}
          </>
        );
      },
    },
    // approve Column
    // {
    //   Header: "",
    //   accessor: "status",
    //   id: "acceptPaymentAndManagerActions",
    //   Cell: cell => {
    //     const row = cell.row.original;
    //     return (
    //       <>
    //         {isPaymentsPage && (
    //           <Flex gap="20px" flexDirection="row">
    //             {(row.status === farmerPaymentRequestStatus.REJECTED ||
    //               row.status === farmerPaymentRequestStatus.PENDING) && (
    //               <Button colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}>Approve </Button>
    //             )}
    //           </Flex>
    //         )}
    //         {!isPaymentsPage && (
    //           <Flex gap="20px" flexDirection="row">
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.DECLINED && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Declined</Badge>
    //             )}
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Processed</Badge>
    //             )}
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.PENDING && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.BLUE_LIGHT}>Pending</Badge>
    //             )}
    //           </Flex>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // reject column
    // {
    //   Header: "",
    //   accessor: "",
    //   id: "rejectPaymentAndMnanagerActions",
    //   Cell: cell => {
    //     const row = cell.row.original;
    //     return (
    //       <>
    //         {isPaymentsPage && (
    //           <Flex gap="20px" flexDirection="row">
    //             {(row.status === farmerPaymentRequestStatus.APPROVED ||
    //               row.status === farmerPaymentRequestStatus.PENDING) && (
    //               <Button
    //                 colorScheme={buttonConstants.colorSchemes.SECONDARY_GRAY}
    //                 variant={buttonConstants.variants.DESTRUCTIVE}
    //               >
    //                 Reject
    //               </Button>
    //             )}
    //           </Flex>
    //         )}
    //         {!isPaymentsPage && (
    //           <Flex gap="20px" flexDirection="row">
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.DECLINED && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.ERROR}>Declined</Badge>
    //             )}
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.PROCESSED && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.SUCCESS}>Processed</Badge>
    //             )}
    //             {row.payment_status === farmerApprovedPaymentRequestStatus.PENDING && (
    //               <Badge colorScheme={badgeConstants.colorSchemes.BLUE_LIGHT}>Pending</Badge>
    //             )}
    //           </Flex>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];
  const paymentsTable = useTable({
    data: farmerPaymentLoading ? [] : farmerPaymentsData,
    columns: !isPaymentsPage
      ? activeTab === "tab3"
        ? columns.filter(col => col.Header !== "CropType" && col.Header !== "Unit(KG)" && col.Header !== "Price/kg")
        : columns.filter(
            col =>
              col.Header !== "CropType" &&
              col.Header !== "Unit(KG)" &&
              col.Header !== "Price/kg" &&
              col.Header !== "Comment"
          )
      : columns.filter(col => col.Header !== "Comment"),
    title: "All payments",
    useServerPagination: true,
    serverPaginationMeta: farmerPaymentsMeta,
    noViewAction: true,
    noEditAction: true,
    // Don't show any actions on payout tab
    noActions: !isPaymentsPage && activeTab === "tab2",
    // checkboxes on tabs with same status
    showCheckboxes:
      (isPaymentsPage && activeTab === "tab2") ||
      (isPaymentsPage && activeTab === "tab3") ||
      (isPaymentsPage && activeTab === "tab4") ||
      (!isPaymentsPage && activeTab === "tab3") ||
      (!isPaymentsPage && activeTab === "tab4"),
    // callbacks
  });
  return paymentsTable;
};

export default usePaymentsTable;
