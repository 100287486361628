import { AdvancedMarker } from "@vis.gl/react-google-maps";
import React, { useCallback } from "react";
export const FarmMarker = ({ farm, onClick, setMarkerRef, ...markerProps }) => {
  const handleClick = useCallback(() => {
    onClick?.(farm);
  }, [farm, onClick]);

  const ref = useCallback(
    marker => {
      setMarkerRef(marker, farm.key);
    },
    [farm.key, setMarkerRef]
  );

  return (
    <AdvancedMarker onClick={handleClick} ref={ref} position={farm.position} {...markerProps}>
      <svg width={24} height={36} viewBox="0 0 24 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_1279_97989" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.2883 18.1802C23.375 16.375 24 14.2605 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 14.2605 0.625031 16.375 1.7117 18.1801L12 36L22.2883 18.1802Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2883 18.1802C23.375 16.375 24 14.2605 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 14.2605 0.625031 16.375 1.7117 18.1801L12 36L22.2883 18.1802Z"
          fill="#101828"
        />
        <path
          d="M22.2883 18.1802L21.4315 17.6644L21.4268 17.6723L21.4223 17.6802L22.2883 18.1802ZM1.7117 18.1801L2.57772 17.6801L2.57315 17.6722L2.56844 17.6644L1.7117 18.1801ZM12 36L11.134 36.5L12 38L12.866 36.5L12 36ZM23 12C23 14.0737 22.4271 16.0106 21.4315 17.6644L23.145 18.6959C24.3228 16.7394 25 14.4473 25 12H23ZM12 1C18.0751 1 23 5.92487 23 12H25C25 4.8203 19.1797 -1 12 -1V1ZM1 12C1 5.92487 5.92487 1 12 1V-1C4.8203 -1 -1 4.8203 -1 12H1ZM2.56844 17.6644C1.57287 16.0106 1 14.0737 1 12H-1C-1 14.4473 -0.322813 16.7394 0.854957 18.6959L2.56844 17.6644ZM0.845672 18.6801L11.134 36.5L12.866 35.5L2.57772 17.6801L0.845672 18.6801ZM12.866 36.5L23.1543 18.6802L21.4223 17.6802L11.134 35.5L12.866 36.5Z"
          fill="#F9FFF7"
          mask="url(#path-1-inside-1_1279_97989)"
        />
        <g clipPath="url(#clip0_1279_97989)">
          <path
            d="M14 9.99977L7 16.9998M14.75 13.4998H10.5M16.12 12.1198C16.6829 11.5569 16.9992 10.7934 16.9992 9.99727C16.9992 9.20118 16.6829 8.4377 16.12 7.87477C15.5571 7.31185 14.7936 6.99561 13.9975 6.99561C13.2014 6.99561 12.4379 7.31185 11.875 7.87477L8.5 11.2498V15.4998H12.75L16.12 12.1198Z"
            stroke="#48A928"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1279_97989">
            <rect width={12} height={12} fill="white" transform="translate(6 6)" />
          </clipPath>
        </defs>
      </svg>
    </AdvancedMarker>
  );
};
