import { InfoWindow } from "@vis.gl/react-google-maps";
import { Flex } from "organic";
import React from "react";
import { appGet } from "utils";

const FarmInfoWindow = ({ isOpen, farm, anchor, onClose }) => {
  return (
    isOpen === true && (
      <InfoWindow anchor={anchor} pixelOffset={[0, -2]} onCloseClick={onClose}>
        <Flex flexDirection="column" gap="0.25rem">
          <Flex fontWeight="500" textTransform="capitalize" fontSize="1rem" letterSpacing="1.2">
            {appGet(farm, ["farmer", "first_name"])} {appGet(farm, ["farmer", "last_name"])}
          </Flex>
          <Flex color="gray600">
            {appGet(farm, ["farmer", "ta_number"])} ({appGet(farm, ["crop_type", "name"])})
          </Flex>
          <Flex color="gray400">
            {appGet(farm, ["farmer", "lga"])}, {appGet(farm, ["farmer", "state"])}
          </Flex>
        </Flex>
      </InfoWindow>
    )
  );
};

export default FarmInfoWindow;
